import React, {useState, useEffect} from 'react'
import {EditEmailModal} from "../modals/EditEmailModal"
import Alert from "../../helpers/alert";
import {getHandleBlur, getHandleInput} from "../../utils/dateValidator";

// const profileDeleteUrl = process.env.REACT_APP_STS_DELETE_PROFILE;

export const Profile = (profs) => {
    const {
        handleSubmit,
        handleDelete,
        handleChange,
        emailSubmit,
        show,
        handleClose,
        handleShow, emailHandleChange,
        userProfile, userEmail, isChanged, error, emailError, alert
    } = profs;

    const EmailModalDetails = {show, handleClose, emailHandleChange, emailSubmit, emailError, userProfile, alert};
    const [input, setInput] = useState("");

    const handleInput = (event) => {
        if (event.target.value.length <= 10) {
            const value = getHandleInput(event);
            handleChange({target: {name: 'birthday', value: value}})
            setInput(value);
        }
    };

    const handleBlur = (event) => {
        if (event.target.value.length < 11) {
            const value = getHandleBlur(event);
            if (value) {
                handleChange({target: {name: 'birthday', value: value}})
                setInput(value);
            }
        }
    };

    useEffect(() => {
        if (userProfile?.birthday) {
            setInput(userProfile?.birthday);

        }
    }, [userProfile]);
    return (
        <div className="text-center m-6-auto">
            <Alert alert={alert}/>
            <div className="p-1 py-4">
                <div className="text-center m-6-auto section-title">
                    <h4>Account Information</h4>
                </div>

                <div className="form-group">
                    <label className="userLabel">Email</label>
                    <input type="text" placeholder="Email" id="email" name="email" defaultValue={userEmail}
                           className="form-control" disabled={true}/>
                    <div className="pointer" onClick={handleShow}>Change Email</div>
                </div>
            </div>

            <EditEmailModal {...EmailModalDetails}/>

            <div className="p-1">
                <div className="text-center m-6-auto section-title">
                    <h4>Personal Information</h4>
                </div>

                <div className="form-group">
                    <label className={"userLabel"}>First Name</label>
                    <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        onChange={handleChange}
                        id="firstName"
                        defaultValue={userProfile?.firstName}
                    />
                    <div className="text-danger">{error.firstName}</div>
                </div>

                <div className="form-group">
                    <label className={"userLabel"}>Last Name</label>
                    <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={handleChange}
                        id="lastName"
                        defaultValue={userProfile?.lastName}/>
                    <div className="text-danger">{error.lastName}</div>
                </div>
                <div className="form-group">
                    <label className={"userLabel"}>Primary Phone Number</label>
                    <input
                        type="number"
                        name="phoneNo"
                        className="form-control"
                        onChange={handleChange}
                        placeholder="Primary Phone Number"
                        id="phoneNo"
                        defaultValue={userProfile?.phoneNo}
                    />
                    <div className="text-danger">{error.phoneNo}</div>
                </div>
                <div className="form-group">
                    <label className={"userLabel"}>Date of Birth</label>
                    <input
                        type="text"
                        name="birthday"
                        className="form-control"
                        placeholder="MM/DD/YYYY"
                        id="birthday"
                        value={input}
                        onChange={handleInput}
                        onBlur={handleBlur}
                    />

                    <div className="text-danger">{error.birthday}</div>
                </div>
                <div>
                    <button className="btn btn-primary profile-btn" type="button" onClick={handleSubmit}
                            disabled={isChanged}
                            style={{width: "100%", borderRadius: "0", backgroundColor: "#1A5D0A"}}>Save
                        Changes
                    </button>
                    <button className="btn btn-danger profile-btn" type="button" onClick={handleDelete}
                            style={{width: "100%", borderRadius: "0", backgroundColor: "#5d0a0a"}}>Delete
                        Account
                    </button>
                    {/*<div id="deleteProfile">*/}
                    {/*    <a href={profileDeleteUrl}>Delete Profile</a>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>);
}
