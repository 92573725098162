export const fetchWrapper = {
    get,
    post,
    put,
    delete: _delete,
    oidcpost, login
};

const sessionStorageKey = process.env.REACT_APP_STS_SESSION_STORAGE_KEY;
// const baseUrl108 = process.env.REACT_APP_BASE_URL_108_STOREID;
// const baseUrl = process.env.REACT_APP_BASE_URL;


function addBasicAuth(requestOptions, loginCredentials) {
    if (loginCredentials) {
        const username = loginCredentials?.username;
        const password = loginCredentials?.password;
        const basicAuth = 'Basic ' + btoa(username + ':' + password);
        requestOptions.headers["Authorization"] = basicAuth;
    } else {
        const basicAuth = 'Basic ' + localStorage.getItem('basicauth');
        requestOptions.headers["Authorization"] = basicAuth;
    }

}

async function get(url) {
    let requestOptions = {
        method: "GET",
        headers: {},
    };
    if (sessionStorage.getItem(sessionStorageKey)) {
        requestOptions.headers["Authorization"] = "Bearer " + (JSON.parse(sessionStorage.getItem(sessionStorageKey)))["id_token"];
    } else if (url.includes("admin")) {
        addBasicAuth(requestOptions, null);
    }

    return fetch(url, requestOptions).then(handleResponse);
}


async function post(url, body) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Origin": "https://auth.freshthyme.com"
        },
        body: JSON.stringify(body),
    };
    if (sessionStorage.getItem(sessionStorageKey)) {
        requestOptions.headers["Authorization"] = "Bearer " + (JSON.parse(sessionStorage.getItem(sessionStorageKey)))["id_token"];

    } else if (url.includes("admin")) {
        addBasicAuth(requestOptions, null);
    }

    return fetch(url, requestOptions).then(handleResponse);
}

async function login(url, body) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Origin": "https://auth.freshthyme.com"
        },
    };
    addBasicAuth(requestOptions, body);
    return fetch(url, requestOptions).then(handleResponse);
}


async function oidcpost(url, body) {
    let formBody = [];
    for (let property in body) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
    };
    return fetch(url, requestOptions).then(handleResponse);
}

async function put(url, body) {
    let requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Origin": "https://auth.freshthyme.com"
        },
        body: JSON.stringify(body),
    };

    if (sessionStorage.getItem(sessionStorageKey)) {
        requestOptions.headers["Authorization"] = "Bearer " + (JSON.parse(sessionStorage.getItem(sessionStorageKey)))["id_token"];
    } else if (url.includes("admin")) {
        addBasicAuth(requestOptions, null);
    }
    return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "accept": "application/json"
        },
    };
    if (sessionStorage.getItem(sessionStorageKey)) {
        requestOptions.headers["Authorization"] = "Bearer " + (JSON.parse(sessionStorage.getItem(sessionStorageKey)))["id_token"];
    } else if (url.includes("admin")) {
        addBasicAuth(requestOptions, null);
    }
    return fetch(url, requestOptions).then(handleResponse);
}

async function handleResponse(response) {
    try {
        return response.text().then((text) => {
            try {
                if (response.ok) {
                    if (text) {
                        const data = text && JSON.parse(text);
                        return data;
                    }
                    return {message: 'ok'};
                } else if (response.status === 422) {
                    const data = text && JSON.parse(text);
                    return {error: data.msg || data.message};
                } else if (response.status === 400) {
                    const data = text && JSON.parse(text);
                    return {error: data.error};
                } else if (response.status === 401) {
                    const data = text && JSON.parse(text);
                    return {error: data.error};
                } else if (!response.ok) {
                    const data = text && JSON.parse(text);
                    const error = (data && data.message) || response.statusText;
                    console.log(error);
                    return null;
                }
            } catch (e) {
                return null;
            }
        });

    } catch (e) {
        console.log(e.message);
        return null;
    }
}

//  function switchBaseUrlByStoreNumber(payload, url) {
//     const urlParams = new URLSearchParams(window.location.search);
//     if (urlParams) {
//         const storeNumber = urlParams.get("env");
//         if (storeNumber === "new") {
//             url = url.replaceAll(baseUrl, baseUrl108);
//         }
//     }
//     // else if (payload?.storeNumber === "108") {
//     //     url = url.replaceAll(baseUrl, baseUrl108);
//     // }
//     return url;
// }
